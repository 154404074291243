/* eslint-disable react/jsx-props-no-spreading */
import {useTranslation} from 'react-i18next';
import {HiOutlineCalendar, HiOutlineX} from 'react-icons/hi';
import CustomModalHeader from 'components/modal/customModalHeader';
import {Modal} from 'flowbite-react';
import {useRef, useState} from 'react';
import {themModal} from 'utils/theme';
import '../../form/styles.scss';
import './purchaseOrderModal.scss';
import {orgSelector} from 'redux/selectors';
import {useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import MultiSelect from 'components/select/index'
import GroupButton from 'components/button/groupButton';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css'; // Import a Flatpickr theme
import {exportPurchaseOrderFormOrg} from 'api/purchaseOrderApi';
import {cloneDeep} from 'lodash';
import {toast} from 'react-toastify';

type exportOrderModalProps = {
    openModal: boolean,
    setOpenModal: Function,
    headerTitle?: string,
    queryClient?: any,
    setCurrentPage?: any,
    currentPage?: number,
};
const ExportOrderModal = (props: exportOrderModalProps) => {
    const {
        openModal,
        setOpenModal,
        headerTitle,
        currentPage,
        queryClient,
        setCurrentPage
    } = props;
    const [t] = useTranslation();
    const {organizationId} = useSelector(orgSelector);
    const rootRef = useRef(null);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [statusTypeOptions, setStatusTypeOptions] = useState([
        {label: 'Draft', value: 'Draft'},
        {label: 'Requested', value: 'Requested'},
        {label: 'Ordered', value: 'Ordered'},
        {label: 'Rejected', value: 'Rejected'},
        {label: 'Received', value: 'Received'}
    ]);
    const flatpickrRef = useRef<Flatpickr | null>(null);
    const [dateFilter, setDateFilter] = useState<any>([]);
    const [fDate, setFDate] = useState('');
    const [tDate, setTDate] = useState('');
    const [poStatus, setPoStatus] = useState([]);

    const hookForm = useForm({
        mode: "onChange",
    });

    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        setError,
        reset,
        getValues,
    } = hookForm;

    const toggleHandler = () => {
        setOpenModal(!openModal);
    };

    const handleChangeStatus = (options: any) => {
        setSelectedStatus(options);
        if (options?.length > 0) {
            const statuss: any = [];
            for (let index = 0; index < options?.length; index++) {
                const element = options[index];
                statuss.push(element?.value);
            }
            setPoStatus(statuss);
        } else {
            setPoStatus([]);
        }
    };

    const handleChangeDate = (option: any) => {
        if (option) {
            if (option[0]) {
                setFDate((option[0] as Date).toLocaleDateString());
            }

            if (option[1]) {
                setTDate((option[1] as Date).toLocaleDateString());
            }
            setDateFilter(option);
        } else {
            setFDate('');
            setTDate('');
            setDateFilter([]);
        }
    };

    const handleClearSelection = () => {
        if (flatpickrRef.current) {
            flatpickrRef.current.flatpickr.clear();
            setFDate('');
            setTDate('');
        }
    };

    const handleExport = async () => {
        setFDate(fDate);
        setTDate(tDate);
        setPoStatus(poStatus);
        let statuss = '';
        for (let index = 0; index < poStatus?.length; index++) {
            const status = poStatus[index];
            if (statuss === '') {
                statuss = status;
            } else {
                statuss = `${statuss},${status}`;
            }
        }
        exportPurchaseOrderFormOrg(organizationId, {fDate, tDate, statuss}).then(res => {
            if (res.data || res.status === 200) {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `purchase-order-export.xlsx`);
                document.body.appendChild(link)
                link.click();

                // Remove dom after finish
                document.body.removeChild(link);
            }
        }).catch(err => {
            toast.error(`${t('uploadFile.noItemExport')}`);
        });
    }

    return (
        <div ref={rootRef} className="order-modal-container">
            <Modal
                show={openModal}
                size="md"
                className="order-modal"
                root={rootRef.current ?? undefined}
                onClose={toggleHandler}
                dismissible={false}
                theme={themModal}
            >
                <CustomModalHeader title={headerTitle} toggle={toggleHandler}/>
                <Modal.Body theme={{base: 'flex-12 pb-4 pt-3 px-12'}}>
                    <form>
                        <div className="custom-date-range mt-4 relative">
                            <Flatpickr
                                ref={flatpickrRef}
                                value={dateFilter}
                                onChange={(e: any) => handleChangeDate(e)}
                                options={
                                    {
                                        mode: 'range',
                                        dateFormat: 'M-d-Y'
                                    }
                                }
                                placeholder="Select Date"
                            />
                            {fDate !== '' && tDate !== '' ?
                                <button onClick={handleClearSelection}><HiOutlineX/></button> : <HiOutlineCalendar/>}
                        </div>
                        <div className="mt-6 relative">
                            <MultiSelect
                                onChange={(option: any) => handleChangeStatus(option)}
                                options={statusTypeOptions}
                                value={selectedStatus}
                                isMulti={true}
                                hookForm={hookForm}
                                name="status"
                                noOptionsMessage={() => t('No options')}
                                closeMenuOnSelect
                                isHolderLabel={true}
                                placeholder={`${t('itemPage.status')}`}
                            />
                        </div>
                        <GroupButton
                            className="items-center justify-center pt-5 mb-4"
                            buttons={[
                                {
                                    type: 'button',
                                    text: t('modal.cancel'),
                                    classType: 'white',
                                    action: () => toggleHandler(),
                                },
                                {
                                    type: 'button',
                                    text: t('modal.export'),
                                    classType: 'blue',
                                    isLoading: false,
                                    action: () => handleExport(),
                                },
                            ]}
                        />
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};
export default ExportOrderModal;
