
import React, { useState } from "react";
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';

interface multiSelectProps {
    data: any,
    selectedData: any,
    setSelectedData: any,
    placeholder: string
}

const SelectMulti = (props: multiSelectProps) => {
    const { data, selectedData, setSelectedData, placeholder } = props

    const template = (option: any) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                <div>{option.name}</div>
            </div>
        );
    };

    const handleSelect = (option: any) => {
        setSelectedData(option.value)
    }

    const panelFooterTemplate = () => {
        const length = selectedData ? selectedData.length : 0;

        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    };

    return (
        <div className="card flex justify-content-center">
            <MultiSelect value={selectedData} options={data} onChange={(e: MultiSelectChangeEvent) => handleSelect(e)} optionLabel="name" 
                placeholder={placeholder} itemTemplate={template} panelFooterTemplate={panelFooterTemplate} className="w-full md:w-20rem" display="chip" />
        </div>
    );
}

export default SelectMulti;
        