// eslint-disable-next-line
import { purchaseOrderRequestQueryFilter } from 'utils/proptypes';
import base from './baseApi';
import { omitBy } from 'lodash';
import { createSearchParams } from 'react-router-dom';
import axios from 'axios';

const { urls, methods, execute } = base();
const getPurchaseOrdersByFilter = (queryFilter: purchaseOrderRequestQueryFilter) => {
  const method = methods.GET;
  const initParams: any = omitBy(queryFilter, v => v === '');
  const params = createSearchParams(initParams);

  const url = `${urls.purchaseOrder.purchaseOrder}/?${params.toString()}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getPurchaseOrders = () => {
  const method = methods.GET;
  const url = `${urls.purchaseOrder.purchaseOrder}?limit=0`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getPurchaseOrderById = (id: string) => {
  const method = methods.GET;
  const url = `${urls.purchaseOrder.purchaseOrder}/${id}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const createPurchaseOrder = (payload: any) => {
  const method = methods.POST;
  const url = `${urls.purchaseOrder.purchaseOrder}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const updatePurchaseOrderById = (id: any,payload: any) => {
  const method = methods.PUT;
  const url = `${urls.purchaseOrder.purchaseOrder}/${id}`;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

const deletePurchaseOrderById = (id: string) => {
    const method = methods.DELETE;
    const url = `${urls.purchaseOrder.purchaseOrder}/${id}`;
    const response = execute(method, url, { authorization: true });
  
    return response;
  };

const exportPurchaseOrderFormOrg = (orgId: string, queryFilter: purchaseOrderRequestQueryFilter) => {
    const method = methods.GET;
    const initParams: any = omitBy(queryFilter, v => v === '');
    const params = createSearchParams(initParams);
    const url = `${urls.purchaseOrder.purchaseOrder}/${orgId}/export-purchase-order/?${params.toString()}`;
    const response = execute(method, url, { authorization: true, responseTye: 'blob' });
  
    return response;
  };

const validatePurchaseOrderByN2G = async (payload: any) => {
    const url = `${urls.purchaseOrder.validateOrderN2G}`;
    let res: any;
    axios.post(url, payload, { headers: { 'Access-Control-Allow-Origin' : url, 'Accept': '*/*'}})
    .then(response => {
      res = response
    })
    .catch(error => {
      res = error.response;
    });
  
    return res;
  };

const createPurchaseOrderByN2G = async (payload: any) => {
    const url = `${urls.purchaseOrder.creationOrderN2G}`;
    let res: any;
    axios.post(url, payload, { headers: { 'Access-Control-Allow-Origin' : url, 'Accept': '*/*'}})
    .then(response => {
      res = response
    })
    .catch(error => {
      console.error("error: ", error.response);
    });
  
    return res;
  };

const receivingPurchaseOrderByN2G = async (orderId: number) => {
    const url = `${urls.purchaseOrder.receivingOrderN2G}?orderId=${orderId}`;
    let res: any;
    axios.post(url, { headers: { 'Access-Control-Allow-Origin' : url, 'Accept': '*/*'}})
    .then(response => {
      res = response
    })
    .catch(error => {
      console.error(error.response);
    });
  
    return res;
  };

export {
    getPurchaseOrdersByFilter,
    getPurchaseOrders,
    getPurchaseOrderById,
    createPurchaseOrder,
    updatePurchaseOrderById,
    deletePurchaseOrderById,
    exportPurchaseOrderFormOrg,
    validatePurchaseOrderByN2G,
    createPurchaseOrderByN2G,
    receivingPurchaseOrderByN2G
};
