// eslint-disable-next-line
import { itemQueryFilter, itemQueryFilterByCategory } from 'utils/proptypes';
import base from './baseApi';
import { omitBy } from 'lodash';
import { createSearchParams } from 'react-router-dom';

const { urls, methods, execute } = base();
const getItemsByFilter = (queryFilter: itemQueryFilter) => {
  const method = methods.GET;
  const initParams: any = omitBy(queryFilter, v => v === '');
  const params = createSearchParams(initParams);

  const url = `${urls.item.item}/?${params.toString()}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getItemsByCategory = (queryFilter: itemQueryFilterByCategory) => {
  const method = methods.GET;
  const initParams: any = omitBy(queryFilter, v => v === '');
  const params = createSearchParams(initParams);

  const url = `${urls.item.item}/by-category?${params.toString()}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getItems = () => {
  const method = methods.GET;
  const url = `${urls.item.item}?limit=0`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getItemById = (id: string) => {
  const method = methods.GET;
  const url = `${urls.item.item}/${id}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getItemByUpc = (upc: string, organizationId: string) => {
  const method = methods.GET;
  const url = `${urls.item.item}/upc/${upc}/${organizationId}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const createItem = (payload: any) => {
  const method = methods.POST;
  const url = `${urls.item.item}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const updateItemById = (id: any,payload: any) => {
  const method = methods.PUT;
  const url = `${urls.item.item}/${id}`;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

const mergeItem = (sourceId: any,desId: any) => {
  const method = methods.PUT;
  const url = `${urls.item.item}/merge/${sourceId}/${desId}`;
  const response = execute(method, url, { authorization: true });
  return response;
};

const deleteItemById = (id: string) => {
    const method = methods.DELETE;
    const url = `${urls.item.item}/${id}`;
    const response = execute(method, url, { authorization: true });
  
    return response;
  };

export {
    getItemsByFilter,
    getItemsByCategory,
    getItems,
    getItemById,
    getItemByUpc,
    createItem,
    updateItemById,
    mergeItem,
    deleteItemById
};
