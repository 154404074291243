import { FileUploader } from "react-drag-drop-files";
import './dragDropFileUpload.scss';
import {HiOutlineUpload} from "react-icons/hi";
import { useTranslation } from 'react-i18next';

const DragDropFileUpload = (props: any) => {
  const { fileTypes, file,  setFile, fileContent, setFileContent, headerUpload, isMulti = false} = props;
  const [t] = useTranslation();

  const handleChange = (file: any) => {
    setFile(file);
    const reader = new FileReader();

    reader.onload = (event: any) => {
      const content = event.target.result;
      setFileContent(content);
    };

    reader.readAsText(file);
  };
  return (
    <>
      <p>{headerUpload}</p>
      <FileUploader
        classes="custom-fileUploader"
        multiple={isMulti}
        handleChange={handleChange}
        name="file"
        label={`${t('uploadFile.labelUpload')}`}
        types={fileTypes}
      >
          <HiOutlineUpload size={"50px"} className="icon"/>
          <span>{`${t('uploadFile.labelUpload')}`}</span>
      </FileUploader>
      {file ? <span className="text-xs font-semibold text-black-500">{t('uploadFile.fileName')}: {file.name}</span> : <span className="text-xs font-semibold text-orange-500">{t('uploadFile.noFileUpload')}</span>}
    </>
  );
}

export default DragDropFileUpload;