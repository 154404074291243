/* eslint-disable react/jsx-props-no-spreading */
import {useTranslation} from 'react-i18next';
import {useMutation} from '@tanstack/react-query';
import CustomModalHeader from 'components/modal/customModalHeader';
import GroupButton from 'components/button/groupButton';
import {toast} from 'react-toastify';
import {messageErrors} from 'utils/utils';
import useUser from 'hooks/useUser';
import {Label, Modal, Radio, Table, TextInput} from 'flowbite-react';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {useEffect, useRef, useState} from 'react';
import {themModal} from 'utils/theme';
import '../../form/styles.scss';
import './itemModal.scss';
import MultiSelect from 'components/select/index';
import {orgSelector} from 'redux/selectors';
import {useSelector} from 'react-redux';
import {getCategoriesByFilter} from 'api/categoryApi';
import {apiStatus, sortByTypeType, status} from 'utils/constants';
import {createItem, getItemsByFilter, mergeItem, updateItemById} from 'api/itemApi';

type mergeItemModalProps = {
    openModal: boolean,
    setOpenModal: Function,
    headerTitle?: string,
    queryClient?: any,
    setCurrentPage?: any,
    currentPage?: number,
};
const MergeItemModal = (props: mergeItemModalProps) => {
    const {openModal, setOpenModal, headerTitle, currentPage, queryClient, setCurrentPage} = props;
    const {fetchCurrentUser} = useUser();
    const [t] = useTranslation();
    const rootRef = useRef(null);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [categoryTypeOptions, setCategoryTypeOptions] = useState([{label: '...', value: ''}]);
    const [error, setErrors] = useState(false);
    const {organizationId} = useSelector(orgSelector);
    const [sourceItemTypeOptions, setSourceItemTypeOptions] = useState([{label: '...', value: ''}]);
    const [sourceItemSelected, setSourceItemSelected] = useState<any>(null);
    const [desItemTypeOptions, setDesItemTypeOptions] = useState([{label: '...', value: ''}]);
    const [desItemSelected, setDesItemSelected] = useState<any>(null);

    const ValidateSchema = Yup.object().shape({
        name: Yup.string().required(t('userManagementPage.requiredField')).max(150, t('locationModal.maxLength150')),
        sku: Yup.string().required(t('requiredField')),
    });

    const hookForm = useForm({
        mode: 'onChange',
        resolver: yupResolver(ValidateSchema),
    });

    const {register, handleSubmit, setValue, clearErrors, setError, reset, getValues} = hookForm;

    useEffect(() => {
        getCategoriesByFilter({page: 0, limit: 0, sortByType: sortByTypeType.DESC, organizationId})?.then(res => {
            const newOption = res?.data?.entities?.map((i: any) => ({label: i.name, value: i.id}));
            setCategoryTypeOptions(newOption);
        });

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getItemsByFilter({
            page: 0,
            limit: 0,
            sortByType: sortByTypeType.DESC,
            organizationId,
            isActive: true
        })?.then(res => {
            const newOption = res?.data?.entities?.map((i: any) => ({label: i.name, value: i.id}));
            setSourceItemTypeOptions(newOption);
        });

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (sourceItemSelected) {
            getItemsByFilter({
                page: 0,
                limit: 0,
                sortByType: sortByTypeType.DESC,
                organizationId,
                isActive: true
            })?.then(res => {
                const newOption = res?.data?.entities?.map((i: any) => ({label: i.name, value: i.id}));
                setDesItemTypeOptions(newOption?.filter((x: any) => x.value !== sourceItemSelected?.value));
            });
        }

        // eslint-disable-next-line
    }, [sourceItemSelected]);

    const handleAddAndUpdateItem = async (payload: any) => {
    };

    const handleMergeItem = async () => {
        if (!desItemSelected) {
            toast.error(`${t('itemPage.selectDesItem')}`);
        } else {
            const res = await mergeItem(sourceItemSelected.value, desItemSelected.value);
            if (res?.status === apiStatus.NO_CONTENT) {
                toggleHandler();
                if (currentPage !== 0) {
                    queryClient.invalidateQueries('getItems', {refetchActive: false}, {cancelRefetch: true});
                    setCurrentPage(0);
                } else queryClient.invalidateQueries('getItems', {refetchActive: true}, {cancelRefetch: true});
                const message: string = t('itemPage.editSuccess');
                toast.success(message);
                fetchCurrentUser();
            }
        }
    };

    const mutation = useMutation(['create-update-item'], {mutationFn: handleAddAndUpdateItem});

    const onSubmit = (values: any, props: any) => {
        mutation.mutate(values, {
            onSuccess: () => {
                toggleHandler();
                if (currentPage !== 0) {
                    queryClient.invalidateQueries('getItems', {refetchActive: false}, {cancelRefetch: true});
                    setCurrentPage(0);
                } else queryClient.invalidateQueries('getItems', {refetchActive: true}, {cancelRefetch: true});
                const message: string = t('itemPage.editSuccess');
                toast.success(message);
                fetchCurrentUser();
            },
            onError: error => {
                const message: string = messageErrors(error, t);
                toast.error(message);
            },
        });
    };

    const handleChangeSourceItem = (option: any) => {
        setSourceItemSelected(option);
    };

    const handleChangeDesItem = (option: any) => {
        setDesItemSelected(option);
    };

    const toggleHandler = () => {
        setOpenModal(!openModal);
        reset();
    };

    return (
        <div ref={rootRef} className="">
            <Modal
                show={openModal}
                size="md"
                className=""
                root={rootRef.current ?? undefined}
                onClose={toggleHandler}
                dismissible={true}
                theme={themModal}
            >
                <CustomModalHeader title={headerTitle} toggle={toggleHandler}/>
                <Modal.Body theme={{base: 'flex-12 pb-4 pt-3 px-12'}}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mt-2 relative flex md:flex-row flex-col">
                            <MultiSelect
                                className="md:mr-4 mb-2 md:mb-0"
                                onChange={handleChangeSourceItem}
                                options={sourceItemTypeOptions}
                                value={sourceItemSelected}
                                hookForm={hookForm}
                                name="sourceItemId"
                                noOptionsMessage={() => t('No options')}
                                closeMenuOnSelect
                                isHolderLabel={true}
                                placeholder={`${t('itemPage.modal.modalSourceItem')}`}
                            />
                        </div>
                        <div className="mt-6 relative flex md:flex-row flex-col">
                            <MultiSelect
                                className="md:mr-4 mb-2 md:mb-0"
                                onChange={handleChangeDesItem}
                                options={desItemTypeOptions}
                                value={desItemSelected}
                                hookForm={hookForm}
                                name="desItemId"
                                noOptionsMessage={() => t('No options')}
                                closeMenuOnSelect
                                isHolderLabel={true}
                                placeholder={`${t('itemPage.modal.modalDesItem')}`}
                            />
                        </div>
                        <GroupButton
                            className="items-center justify-center pt-5 mb-4"
                            buttons={[
                                {
                                    type: 'button',
                                    text: t('modal.cancel'),
                                    classType: 'white',
                                    action: () => toggleHandler(),
                                },
                                {
                                    type: 'button',
                                    text: t('itemPage.mergeItem'),
                                    classType: 'blue',
                                    isLoading: mutation.isLoading,
                                    action: () => handleMergeItem(),
                                },
                            ]}
                        />
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};
export default MergeItemModal;
