// eslint-disable-next-line
import { stockExportQueryFilter, stockLedgerQueryFilter, stockQueryFilter } from 'utils/proptypes';
import base from './baseApi';
import { omitBy } from 'lodash';
import { createSearchParams } from 'react-router-dom';

const { urls, methods, execute } = base();
const getStocksByFilter = (queryFilter: stockQueryFilter) => {
  const method = methods.GET;
  const initParams: any = omitBy(queryFilter, v => v === '');
  const params = createSearchParams(initParams);

  const url = `${urls.stock.stock}/?${params.toString()}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getStockLedgersByFilter = (queryFilter: stockLedgerQueryFilter) => {
  const method = methods.GET;
  const initParams: any = omitBy(queryFilter, v => v === '');
  const params = createSearchParams(initParams);

  const url = `${urls.stock.stock}/stock-ledger?${params.toString()}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getStocks = () => {
  const method = methods.GET;
  const url = `${urls.stock.stock}?limit=0`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getStockById = (id: string) => {
  const method = methods.GET;
  const url = `${urls.stock.stock}/${id}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getStockByItemAndLocation = (locationId: string, itemId: string) => {
  const method = methods.GET;
  const url = `${urls.stock.stock}/by-item/${locationId}/${itemId}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getStockItemReconcile = (payload: any, orgId: string, locationId: string) => {
  const method = methods.POST;
  const url = `${urls.stock.stock}/reconcile/${orgId}/${locationId}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const createStock = (payload: any) => {
  const method = methods.POST;
  const url = `${urls.stock.stock}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const updateStockById = (id: any,payload: any) => {
  const method = methods.PUT;
  const url = `${urls.stock.stock}/${id}`;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};

const deleteStockById = (id: string) => {
    const method = methods.DELETE;
    const url = `${urls.stock.stock}/${id}`;
    const response = execute(method, url, { authorization: true });
  
    return response;
  };

  const exportStockFormOrg = (orgId: string, queryFilter: stockExportQueryFilter) => {
    const method = methods.GET;
    const initParams: any = omitBy(queryFilter, v => v === '');
    const params = createSearchParams(initParams);
    const url = `${urls.stock.stock}/${orgId}/export-stock/?${params.toString()}`;
    const response = execute(method, url, { authorization: true, responseTye: 'blob' });
  
    return response;
  };

  const exportStockLedgerFormOrg = (orgId: string) => {
    const method = methods.GET;
    const url = `${urls.stock.stock}/${orgId}/export-stock-ledger`;
    const response = execute(method, url, { authorization: true, responseTye: 'blob' });
  
    return response;
  };

  const statisticsSaleItem = (itemId: string, locationId: string, numDay: number) => {
    const method = methods.GET;
    const url = `${urls.stock.stock}/${locationId}/statistics/${itemId}/${numDay}`;
    const response = execute(method, url, { authorization: true });
  
    return response;
  };

export {
    getStocksByFilter,
    getStockLedgersByFilter,
    getStocks,
    getStockById,
    getStockByItemAndLocation,
    getStockItemReconcile,
    createStock,
    updateStockById,
    deleteStockById,
    exportStockFormOrg,
    exportStockLedgerFormOrg,
    statisticsSaleItem
};
